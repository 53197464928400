import React, { FC } from "react";
import StaySearchForm, { StaySearchFormFields } from "./StaySearchForm";

export interface HeroSearchFormProps {
  className?: string;
  defaultFieldFocus?: StaySearchFormFields;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  defaultFieldFocus,
}) => {
  return (
    <div
      className={`nc-HeroSearchForm ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <div className="mt-20 pt-4">
        <StaySearchForm defaultFieldFocus={defaultFieldFocus} />
      </div>
    </div>
  );
};

export default HeroSearchForm;
