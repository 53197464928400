import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/listing-stay-map",
    name: "Apartments",
  },
  {
    id: ncNanoId(),
    href: "/partner",
    name: "Partner",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Why Liiv",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/author",
  //   name: "Templates",
  //   type: "dropdown",
  //   children: templatesChildrenMenus,
  // },
];
