import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h1
            id="heading"
            className="font-medium text-3xl sm:text-5xl md:text-4xl xl:text-5xl !leading-[114%]"
            style={{ width: "max-content" }}
          >
            <p>Furnished Apartments.</p>
            <p>One Monthly Price.</p>
            <p>Flexible Leasing.</p>
          </h1>
          <ButtonPrimary className="hidden lg:block">
            Find your home
          </ButtonPrimary>
          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3 w-full">
            <HeroSearchForm2MobileFactory />
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
